var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu-wrap",class:{ openmenu: _vm.openMenu }},[_c('div',{staticClass:"menu-btn",on:{"click":function($event){_vm.openMenu = true}}},[_c('img',{attrs:{"src":require("@/assets/menu.svg")}})]),_vm._m(0),_c('div',{staticClass:"side-menu"},[_c('div',{staticClass:"close-menu",on:{"click":function($event){_vm.openMenu = false}}},[_c('img',{attrs:{"src":require("@/assets/close.svg")}})]),_vm._m(1),_c('div',{staticClass:"menu"},_vm._l((_vm.menuItems),function(item,i){return _c('p',{key:item.id,staticClass:"side-link",class:{ active: i == _vm.activeIndex },attrs:{"href":`/#${item.id}`},on:{"click":_vm.gotosection}},[_vm._v(" "+_vm._s(item.title)+" ")])}),0),_vm._m(2),_c('div',[_c('a',{staticClass:"Login",attrs:{"href":"https://portal.emagine.care/login","target":"_blank"}},[_vm._v("Study portal")]),_vm._m(3),_vm._m(4),_vm._m(5),_c('router-link',{staticClass:"small-side-link",attrs:{"to":"/privacy-policy"}},[_vm._v("Privacy Policy")]),_c('router-link',{staticClass:"small-side-link",attrs:{"to":"/terms-and-conditions"}},[_vm._v("Terms & Conditions")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-logo"},[_c('img',{attrs:{"src":require("@/assets/logov2.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"top-logo",attrs:{"href":"/#home"}},[_c('img',{attrs:{"src":require("@/assets/logov2.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"txt13"},[_vm._v("Interested in participating?")]),_c('a',{staticClass:"btn",attrs:{"href":"/#eligibility"}},[_vm._v("Am I Eligible?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"soc-link",attrs:{"href":"https://www.facebook.com/BrainQTech","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/facebook.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"soc-link",attrs:{"href":"https://www.linkedin.com/company/brainq-technologies/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/linkdin.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"brainq-logo",attrs:{"href":"https://brainqtech.com/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/logo-2024.svg"),"alt":"BrainQ"}})])
}]

export { render, staticRenderFns }