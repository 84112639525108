var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-ring",class:{ 'center-loader': _vm.height },style:({ height: _vm.height })},[_c('div',{style:({
      'border-color': `${_vm.color} #cccccc #cccccc #cccccc`,
    })}),_c('div',{style:({
      'border-color': `${_vm.color} #cccccc #cccccc #cccccc`,
    })}),_c('div',{style:({
      'border-color': `${_vm.color} #cccccc #cccccc #cccccc`,
    })}),_c('div',{style:({
      'border-color': `${_vm.color} #cccccc #cccccc #cccccc`,
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }