<template>
  <div
    class="lds-ring"
    :style="{ height: height }"
    :class="{ 'center-loader': height }"
  >
    <div
      :style="{
        'border-color': `${color} #cccccc #cccccc #cccccc`,
      }"
    ></div>
    <div
      :style="{
        'border-color': `${color} #cccccc #cccccc #cccccc`,
      }"
    ></div>
    <div
      :style="{
        'border-color': `${color} #cccccc #cccccc #cccccc`,
      }"
    ></div>
    <div
      :style="{
        'border-color': `${color} #cccccc #cccccc #cccccc`,
      }"
    ></div>
  </div>
</template>

<script>
export default {
  name: "LoaderAnimation",
  props: {
    height: String,
    size: {
      type: Number,
      default: () => 80,
    },
    color: {
      type: String,
      default: () => "white",
    },
  },
};
</script>

<style scoped>
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 2px solid;
  border-radius: 50%;
  animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.28s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.18s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.1s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.center-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
