<template>
  <div class="menu-wrap" :class="{ openmenu: openMenu }">
    <div class="menu-btn" @click="openMenu = true">
      <img src="@/assets/menu.svg" />
    </div>
    <div class="mobile-logo"><img src="@/assets/logov2.svg" class="" /></div>
    <div class="side-menu">
      <div class="close-menu" @click="openMenu = false">
        <img src="@/assets/close.svg" />
      </div>
      <a href="/#home" class="top-logo"> <img src="@/assets/logov2.svg" /></a>
      <div class="menu">
        <p
          v-for="(item, i) in menuItems"
          :key="item.id"
          :href="`/#${item.id}`"
          class="side-link"
          :class="{ active: i == activeIndex }"
          @click="gotosection"
        >
          {{ item.title }}
        </p>
      </div>
      <div>
        <p class="txt13">Interested in participating?</p>
        <!-- <button @click="openPopup()" class="btn">Am I Eligible?</button> -->
        <a href="/#eligibility" class="btn">Am I Eligible?</a>
      </div>
      <div>
        <a
          class="Login"
          href="https://portal.emagine.care/login"
          target="_blank"
          >Study portal</a
        >
        <a
          href="https://www.facebook.com/BrainQTech"
          target="_blank"
          class="soc-link"
        >
          <img src="@/assets/facebook.svg" /></a
        ><a
          href="https://www.linkedin.com/company/brainq-technologies/"
          target="_blank"
          class="soc-link"
        >
          <img src="@/assets/linkdin.svg"
        /></a>

        <a href="https://brainqtech.com/" target="_blank" class="brainq-logo">
          <img src="@/assets/logo-2024.svg" alt="BrainQ"
        /></a>

        <router-link to="/privacy-policy" class="small-side-link"
          >Privacy Policy</router-link
        >
        <router-link to="/terms-and-conditions" class="small-side-link"
          >Terms & Conditions</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  data: () => ({
    openMenu: false,
    activeIndex: 0,
    menuItems: [
      { title: "Overview", id: "overview" },
      { title: "About the BQ System", id: "about" },
      { title: "Study Objectives", id: "objectives" },
      { title: "Living with the Therapy", id: "living-with-the-therapy" },
      { title: "The Science Behind", id: "science-behind" },
      { title: "Study Locations", id: "locations" },
      { title: "Steering Committee", id: "steering-committee" },
      { title: "FAQ", id: "faq" },
      { title: "Contact", id: "contact" },
    ],
  }),
  watch: {
    "$route.path"() {
      if (this.openMenu) this.openMenu = false;
    },
  },
  mounted() {
    for (let index in this.menuItems)
      this.observe({ id: this.menuItems[index].id, index });
  },
  methods: {
    gotosection(e) {
      this.openMenu = false;
      const id = e.target.getAttribute("href").split("#").pop();
      if (id)
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    },
    observe({ id, index }) {
      const el = document.getElementById(id);
      this.elObserve = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (!entry.isIntersecting) return;
            else this.activeIndex = index;
          });
        },
        { threshold: 0, rootMargin: "-200px" }
      );
      this.elObserve.observe(el);
    },
    async openPopup() {
      // if (this.$router)
      if (this.$route.path != "/") {
        await this.$router.push({ path: "/" });
        setTimeout(() => {
          document.querySelector("#eligibility button").click();
        }, 300);
      }
      this.$emit("openPopup", 1);
    },
  },
};
</script>
