<template>
  <div class="behind-box" :class="{ behindopen: behindOpen }">
    <p @click="behindOpen = !behindOpen" class="behind-q">{{ question }}</p>
    <img
      @click="behindOpen = !behindOpen"
      src="@/assets/small-down.svg"
      class="behind-arrow"
    />
    <div v-html="answer" class="behind-a"></div>
  </div>
</template>

<script>
export default {
  name: "BehindBox",
  data: () => ({
    behindOpen: false,
  }),
  props: {
    _id: { type: String, default: "" },
    question: { type: String, default: "" },
    answer: { type: String, default: "" },
  },
};
</script>
