import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
Vue.use(VueRouter);
import Bagel from "@bageldb/bagel-db";

import PopUp from "vue-bgl-popup";
import store from "./store";

Vue.component("PopUp", PopUp);
Vue.prototype.$db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
