import termsAndConditions from "../views/TermsAndConditions.vue";
import privacyPolicy from "../views/PrivacyPolicy.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import UnderConstruction from "../views/UnderConstruction.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/",
    name: "UnderConstruction",
    component: UnderConstruction,
  },
  {
    path: "/privacy-policy",
    name: "Privacy-Policy",
    component: privacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    name: "Terms-and-Conditions",
    component: termsAndConditions,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
