<template>
  <div class="committee-person-box">
    <div class="committee-inner-box" @click="committeeOpen = true">
      <img :src="image.imageURL" :alt="name" class="committee-img" />
      <p class="committee-name">{{ name }}</p>
      <a>Read Bio</a>
    </div>
    <PopUp v-model="committeeOpen" width="1060px">
      <div class="committee-bio-wrap">
        <div @click="closeBtn" class="closeme">
          <img src="@/assets/close.svg" alt="close" />
        </div>
        <p class="committee-name">{{ name }}</p>
        <div v-html="bio" class="committee-bio"></div>
      </div>
    </PopUp>
  </div>
</template>
<script>
export default {
  name: "CommitteeBox",
  data: () => ({
    committeeOpen: false,
    newCloseBtn: null,
  }),
  props: {
    _id: { type: String, default: "" },
    name: { type: String, default: "" },
    bio: { type: String, default: "" },
    image: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    closeBtn() {
      const OGCloseBtn = document.querySelector(".close-pop");
      OGCloseBtn.click();
    },
  },
};
</script>
