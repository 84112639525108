<template>
  <div class="faq-box" :class="{ faqopen: faqOpen }">
    <div @click="faqOpen = !faqOpen" class="faq-mark-wrap">
      <img src="@/assets/pls.svg" v-if="!faqOpen" class="pls" />
      <img src="@/assets/min.svg" class="min" />
    </div>
    <p @click="faqOpen = !faqOpen" class="faq-q">{{ question }}</p>
    <div v-html="answer" class="faq-a"></div>
  </div>
</template>
<script>
export default {
  name: "FaqBox",
  data: () => ({
    faqOpen: false,
  }),
  props: {
    _id: { type: String, default: "" },
    question: { type: String, default: "" },
    answer: { type: String, default: "" },
  },
};
</script>
