<template>
  <div>
    <sidemenu></sidemenu>
    <div class="main-wrap">
      <div class="w1200">
        <div class="legal-wrap">
          <router-link to="/" class="back-btn"
            ><img src="@/assets/back.svg" />Back
          </router-link>
          <h1 class="txt55">{{ legal.title }}</h1>
          <p v-if="legal.effectiveDate" class="legal-date">
            Effective Date:{{ legal.effectiveDate }}
          </p>
          <loader v-if="!legal.content" height="800px" />
          <div v-html="legal.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidemenu from "@/components/Sidemenu.vue";
import Loader from "../components/loader.vue";
export default {
  components: {
    Sidemenu,
    Loader,
  },
  data: () => ({
    legal: "",
  }),
  async beforeMount() {
    let { data: legal } = await this.$db
      .collection("legal")
      .item("626a94d9d5975410bffa8a90")
      .get();
    this.legal = legal;
  },
  name: "Terms-And-Conditions",
};
</script>
