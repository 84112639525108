<template>
  <div>
    <sidemenu @openPopup="popupOpen = 1"></sidemenu>
    <div class="main-wrap" id="home">
      <div class="w1200">
        <div class="main-txt-wrap">
          <div class="main-head-wrap">
            <h1 class="main-head">EMAGINE Stroke Recovery</h1>
            <h2 class="sub-head">Pivotal Study</h2>
            <p>BQ therapy: recovery after stroke from the comfort of home</p>
            <div class="btn-wrap">
              <a href="/#eligibility" class="btn">Check Your Eligibility</a>
            </div>
          </div>
          <!-- <div class="nih-wrap">
            <img src="@/assets/nih.svg" class="nih-logo" />
            <p class="nih-txt">Reference to the EMAGINE trial</p>
            <a
              href="https://www.clinicaltrials.gov/ct2/show/NCT05044507?term=NCT05044507&draw=2&rank=1"
              target="_blank"
              class="nih-link"
              >clinicaltrials.gov</a
            >
          </div> -->
        </div>
        <img src="@/assets/hero.jpg" class="main-img" />

        <div class="overview-sec" id="overview">
          <div class="org-oval"></div>
          <div class="gray-box">
            <h3 class="txt55">Overview</h3>
            <p>
              This website is designed to assist patients, family members, and
              caregivers in obtaining valuable information about the EMAGINE
              study, and to support the process of eligibility evaluation, and
              research study enrollment for potential participants.
            </p>
            <p>
              The BQ 2.0 system (designed and manufactured by BrainQ
              Technologies Ltd.) is an investigational device, meaning it has
              not been approved by the FDA, the use of which has had no related
              adverse events to date.
            </p>
          </div>
          <div class="overview-img-wrap">
            <img src="@/assets/IGFR9402.jpg" class="overview-img" />
          </div>
          <p></p>
          <p></p>
        </div>
      </div>
      <div class="tool-wrap" id="eligibility">
        <div class="tool-sec" v-if="!applied">
          <div></div>
          <div class="popup" v-if="popupOpen === 1">
            <h3 class="pophead">Check Your Eligibility</h3>
            <p><b>Introductory Statement</b></p>

            <p>
              Thank you for your interest in the research study we are
              conducting. The study aims to evaluate the safety and
              effectiveness of frequency-tuned electromagnetic field treatment
              in facilitating recovery in individuals with moderate to severe
              disability following ischemic stroke.
            </p>
            <p>
              Participation in the study would last approximately nine weeks and
              require you to actively participate in device guided therapy
              sessions in inpatient and\or home settings.
            </p>
            <p>
              To see if you might qualify for this study, we will be required to
              ask a few questions about your health history and present
              condition. Some of these questions may be regarded as sensitive.
              You do not have to answer any questions you do not want to answer.
              If you do not qualify for this study, the information you provide
              will be stored in BrainQ’s secured server for one year and then
              deleted. In case you are found eligible for the study, the
              relevant participating center will reach out to you to coordinate
              the next steps.
            </p>
            <form @submit.prevent="checkEligibility(2)">
              <input
                type="checkbox"
                required
                id="agree"
                name="agree"
                value="agree"
              /><label for="agree" class="label">
                I have read these terms and agree to proceed</label
              >
              <div class="btn-wrap">
                <button type="submit" class="btn wide">Next</button>
              </div>
            </form>
          </div>
          <form
            @submit.prevent="checkEligibility(3)"
            class="popup"
            v-if="popupOpen == 2"
          >
            <h3 class="pophead">Patient Eligibility</h3>
            <p>
              Answer the following questions to check the eligibility of the
              patient for this study
            </p>

            <div class="small-blue-line"></div>
            <p class="question">What is the candidate’s age?</p>
            <div class="age">
              <input
                v-model="eligibility.age"
                type="radio"
                id="age1"
                name="age"
                value="Under 22"
              />
              <label for="age1">Under 22</label><br />
              <input
                v-model="eligibility.age"
                type="radio"
                id="age2"
                name="age"
                value="22-35"
              />
              <label for="age2">22 - 35</label><br />
              <input
                v-model="eligibility.age"
                type="radio"
                id="age3"
                name="age"
                value="36-65"
              />
              <label for="age3">36 - 65</label>
              <input
                v-model="eligibility.age"
                type="radio"
                id="age4"
                name="age"
                value="66-85"
              />
              <label for="age4">66 - 85</label>
              <input
                v-model="eligibility.age"
                type="radio"
                id="age5"
                name="age"
                value="86+"
              />
              <label for="age5">86+</label>
            </div>
            <div class="question-wrap">
              <p class="question">
                Was the candidate diagnosed with an ischemic stroke, confirmed
                by CT or MRI imaging?
              </p>
              <div class="yesno">
                <input
                  type="radio"
                  id="diagnosed1"
                  name="diagnosed"
                  v-model="eligibility.diagnosed"
                  :value="true"
                />
                <label for="diagnosed1">Yes</label>

                <input
                  type="radio"
                  id="diagnosed2"
                  name="diagnosed"
                  v-model="eligibility.diagnosed"
                  :value="false"
                />
                <label for="diagnosed2">No</label>
              </div>
            </div>
            <div class="question-wrap">
              <p class="question">
                Is the candidate four to 21 days from stroke onset?
              </p>
              <div class="yesno">
                <input
                  type="radio"
                  id="question1otp1"
                  name="question1"
                  :value="true"
                  v-model="eligibility.times"
                />
                <label for="question1otp1">Yes</label>

                <input
                  type="radio"
                  id="question1otp2"
                  name="question1"
                  :value="false"
                  v-model="eligibility.times"
                />
                <label for="question1otp2">No</label>
              </div>
            </div>

            <div class="question-wrap">
              <p class="question">
                Is the candidate able to sit wearing a lightweight device for 40
                consecutive minutes?
              </p>
              <div class="yesno">
                <input
                  type="radio"
                  id="question2otp1"
                  name="question2"
                  :value="true"
                  v-model="eligibility.able"
                />
                <label for="question2otp1">Yes</label>
                <input
                  type="radio"
                  id="question2otp2"
                  name="question2"
                  :value="false"
                  v-model="eligibility.able"
                />
                <label for="question2otp2">No</label>
              </div>
            </div>
            <div class="question-wrap">
              <p class="question">
                Is the candidate willing to participate in occupational/physical
                therapy activities during study intervention sessions?
              </p>
              <div class="yesno">
                <input
                  v-model="eligibility.willing"
                  type="radio"
                  id="question3otp1"
                  name="question3"
                  :value="true"
                />
                <label for="question3otp1">Yes</label>

                <input
                  v-model="eligibility.willing"
                  type="radio"
                  id="question3otp2"
                  name="question3"
                  :value="false"
                />
                <label for="question3otp2">No</label>
              </div>
            </div>
            <div class="question-wrap">
              <p class="question">
                Does the candidate have a family member or other caregiver
                available to assist during PT/OT treatment and to operate an
                application installed on a mobile device which involves a video
                call?
              </p>
              <div class="yesno">
                <input
                  v-model="eligibility.caregiver"
                  type="radio"
                  id="caregiver1"
                  name="caregiver"
                  :value="true"
                />
                <label for="caregiver1">Yes</label>

                <input
                  v-model="eligibility.caregiver"
                  type="radio"
                  id="caregiver2"
                  name="caregiver"
                  :value="false"
                />
                <label for="caregiver2">No</label>
              </div>
            </div>
            <p class="question">
              If female, please confirm that the candidate is not pregnant nor
              breastfeeding, has no ability to become pregnant or is willing to
              use an acceptable method of contraception during the study
            </p>
            <div class="yesno question4">
              <input
                v-model="eligibility.pregnant"
                type="radio"
                id="question4opt1"
                name="question4"
                :value="true"
              />
              <label for="question4opt1">Yes</label><br />
              <input
                v-model="eligibility.pregnant"
                type="radio"
                id="question4opt2"
                name="question4"
                :value="false"
              />
              <label for="question4opt2">No</label><br />
            </div>
            <div class="btn-wrap">
              <button type="submit" class="btn wide">Next</button>
            </div>
          </form>
          <div class="popup" v-if="popupOpen == 4 || popupOpen == 3">
            <p style="font-size: x-large; line-height: 1.6">
              Thank you for your interest in enrolling in the EMAGINE trial.
            </p>
            <p style="font-size: x-large; line-height: 1.6">
              Please leave your details to allow a representative to contact you
              to further evaluate.
            </p>
            <form @submit.prevent="submitForm">
              <label>
                Zip Code *
                <input
                  v-model="form.zip"
                  class="txt-input space"
                  type="text"
                  placeholder="Zip Code"
                />
              </label>

              <label>
                Full Name *
                <input
                  v-model="form.name"
                  class="txt-input space"
                  type="text"
                  placeholder="Full Name"
                />
              </label>

              <label>
                Email *
                <input
                  v-model="form.email"
                  class="txt-input space"
                  type="email"
                  placeholder="Email"
                />
              </label>
              <label>
                Phone Number
                <input
                  v-model="form.phone"
                  class="txt-input space"
                  type="tel"
                  placeholder="Phone Number"
                />
              </label>
              <div class="small-blue-line"></div>

              <div class="btn-wrap">
                <button type="submit" class="btn wide">Send</button>
              </div>
            </form>
            <hr />
            <p>
              If you’d rather not share your details, you may contact a relevant
              study site directly from the list of participating sites.
            </p>
            <div class="btn-wrap">
              <a @click="popupOpen = false" href="/#locations" class="btn wide">
                Study Sites
              </a>
            </div>
          </div>
        </div>
        <div class="tool-sec" v-if="applied">
          <h3 class="tool-head">Your application has been received</h3>
          <p class="tool-txt">
            Thank you! we will be in touch with relevant information
          </p>
        </div>
        <div>
          <div
            @click.self="popupOpenUs = false"
            class="pop-small"
            :class="{ opensmallpop: !!popupOpenUs }"
          >
            <p>
              This page provides information about our clinical trials in the US
              and is intended only for potential patients in the US.
            </p>
            <div class="btn-wrap">
              <div @click="handlePopupOpenUsDismiss" class="btn wide">Okay</div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="to-the-right">
          <div class="gray-box phone-side">
            <div
              :style="{
                'max-height': readmore ? '99999px' : '510px',
                overflow: 'hidden',
              }"
            >
              <h3 class="txt34">Participating in the Study</h3>
              <p>
                This study aims to investigate the effect of the BQ therapy on
                regaining a patient’s independence and reducing their global
                disability following an Ischemic stroke.
              </p>
              <img src="@/assets/ipad.png" class="screen" />

              <p>
                The treatment aspires to utilize the ideal window for recovery
                in the first few weeks following a stroke. Treatment begins 4 to
                21 days post-stroke and therapy is administered five times a
                week over the course of 9 weeks.
              </p>

              <p>
                Each therapy session is coupled with a device guided
                physical/occupational therapy regimen that is in line with the
                stroke rehabilitation guidelines, which recommends that patients
                with stroke participate in a home-based rehabilitation program
                to supplement usual care. In this study, there are 60-minute arm
                and leg physical and occupational therapy (PT/OT) sessions that
                are recommended to be completed while wearing the BQ 2.0 device
                (and 20 minutes without the device) with your caregiver. The
                PT/OT session is guided by the BQ app.
              </p>
              <p>
                The sessions may be conducted in either inpatient, or outpatient
                setting. For patients with a significant stroke, it is
                recommended to participate in home-rehabilitation to supplement
                usual care

                <a
                  href="https://www.ahajournals.org/doi/full/10.1161/STR.0000000000000098"
                  target="_blank"
                  >(Winstein et al., 2016)</a
                >
              </p>
              <p>
                Designated caregivers will receive a short training session on
                how to operate the BQ 2.0 device, and will then be able to
                assist the patient and manage the treatment sessions. Sessions
                will be periodically supervised by dedicated site personnel, who
                are available to guide the caregiver and patient as needed.
              </p>
              <p>
                Sessions can be conducted in almost any setting and at any time,
                and require only a few minutes of preparation. During the
                sessions, the caregiver will be in charge of putting on and
                removing the device, operating an easy to use BQ application,
                and assisting the patient in performing device-guided exercises.
              </p>
            </div>
            <br />
            <button class="btn small" v-if="!readmore" @click="readmore = true">
              Read More
            </button>
            <br />
          </div>
        </div>
        <div class="video-wrap middle">
          <div style="padding: 56.25% 0 0 0; position: relative">
            <iframe
              src="https://player.vimeo.com/video/688335580?h=cb13836b1a&title=0&byline=0&portrait=0"
              style="
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              "
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen=""
            ></iframe>
          </div>
          <div class="green-oval"></div>
        </div>
      </div>
      <div class="about-sec" id="about">
        <div
          class="about-wrap hide-mobile-half"
          :class="{ show: readmoreAbout }"
        >
          <h4 class="txt34">About the BQ System</h4>
          <img src="@/assets/VSBrainQ-436.jpg" class="about-img" />
          <p>
            The investigational BQ 2.0 device is a non-invasive (meaning the
            device does not physically enter the body), wearable medical device
            that produces and delivers extremely low intensity and
            frequency-tuned electromagnetic field therapy. The device comes with
            a mobile device from which treatment sessions are controlled. The BQ
            therapy (delivered by the BQ system) aims to stimulate neuronal
            networks with the goal of reducing disability and promoting
            neurorecovery. The BQ 2.0 system has been designed to support stroke
            survivors through their recovery pathway, and can be used in the
            clinic, as well as at home, with the assistance of a caregiver. To
            further support recovery, the BQ mobile device includes a guided
            exercise regimen that is in line with current guidelines for stroke
            patients, to facilitate home-based rehabilitation. This exercise
            regimen is presented through an interactive application that helps
            the user perform exercises.
          </p>

          <p>
            <a
              href="https://www.prnewswire.com/news-releases/brainq-gets-fda-breakthrough-status-for-its-device-for-reducing-disability-following-stroke-301226735.html"
              class="inner-txt-link"
              target="_blank"
              >The investigational BQ 2.0 system has received Breakthrough
              Device Designation from the FDA.</a
            >
            BrainQ is the sponsor of the Electromagnetic Field Ischemic Stroke –
            Novel Subacute Treatment (EMAGINE) trial, investigating the use of
            the BQ 2.0 device ELF-EMF for treating ischemic stroke.
          </p>
        </div>
        <button
          class="btn small mobile"
          v-if="!readmoreAbout"
          @click="readmoreAbout = true"
        >
          Read More
        </button>
        <button
          class="btn small mobile"
          v-if="readmoreAbout"
          @click="readmoreAbout = false"
        >
          Read Less
        </button>
      </div>
      <div class="objectives-sec" id="objectives">
        <h3 class="txt55 obj">EMAGINE Study Objectives</h3>
        <div class="obj-txt-wrap">
          <div class="obj-wrap">
            <p>
              The pivotal study, launched in November 2021, is a double-blind
              sham-controlled randomized trial conducted in multiple US sites.
              The trial evaluates BrainQ’s BQ 2.0, frequency-tuned
              electromagnetic field treatment in facilitating recovery in
              individuals with moderate to severe disability following ischemic
              stroke.
            </p>
            <p class="inlineP">
              The EMAGINE Stroke Recovery Trial provides a unique care model
              that enables treatment in both a clinical facility, and a home
              setting. The study aims to enroll participants 4 to 21 days
              following a stroke. The study is designed to allow participants to
              receive the BQ therapy in multiple settings, including acute care
              facilities, inpatient rehabilitation, and at home. The BQ 2.0
              system is operated by a designated caregiver (following initial
              training sessions with a member from the BrainQ study team), with
              some oversight by a therapist, who may join at times by a
              video/audio call to supervise the session.
            </p>
            <p class="hide-mobile" :class="{ show: readmoreStudy }">
              The trial includes 45 sessions, administered five times a week
              over nine weeks. Each session will last for 60 minutes, 40 minutes
              of which will include exposure to BQ therapy, and subjects will be
              asked to perform a device guided physical therapy exercise
              regimen. This regimen is in line with the neurorehabilitation
              guidelines for stroke patients, which state that patients should
              participate in a home-based rehabilitation program to supplement
              usual care. Investigational therapy does not in any way replace
              the usual care.
            </p>
            <p class="hide-mobile" :class="{ show: readmoreStudy }">
              BrainQ Technologies, the sponsor, maintains this website to help
              patients get basic information about the study.
            </p>
            <p class="inlineP hide-mobile" :class="{ show: readmoreStudy }">
              When at home, the BQ 2.0 system is operated by a designated
              caregiver (following initial training sessions with a member from
              the BrainQ study team), with occasional oversight by a therapist,
              who will join by a video/audio call to supervise the session.
            </p>
            <p class="hide-mobile" :class="{ show: readmoreStudy }">
              If you want to participate in the EMAGINE trial, please review our
              <a class="nih-link" href="/#eligibility"
                >pre-screening questionnaire</a
              >

              and consider sharing your contact details so that someone from the
              EMAGINE team can contact you directly<br /><br />
            </p>
            <div class="video-wrap">
              <div style="padding: 56.25% 0 0 0; position: relative">
                <iframe
                  src="https://player.vimeo.com/video/688335464?h=cb13836b1a&title=0&byline=0&portrait=0"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen=""
                ></iframe>
              </div>
            </div>

            <p class="blockp hide-mobile" :class="{ show: readmoreStudy }">
              The EMAGINE study runs in selected medical centers across the US.
              An updated list of these centers and their contact information can
              be accessed at the following link:
            </p>
            <p
              class="txt20 hide-mobile blockp"
              :class="{ show: readmoreStudy }"
            >
              <a
                href="https://www.clinicaltrials.gov/ct2/show/NCT05044507?term=NCT05044507&amp;draw=2&amp;rank=1"
                target="_blank"
                class="nih-link"
              >
                Reference to the EMAGINE trial on clinicaltrials.gov</a
              >
            </p>
            <p class="hide-mobile" :class="{ show: readmoreStudy }">
              If you’re unable to reach a study center or for any further
              information - please leave your contact info in the
              <a class="nih-link" href="/#contact">“contact us”</a>
              section.
            </p>
          </div>
          <!-- <div class="obj-side-wrap hide-mobile" :class="{ show: readmoreStudy }">
              <p class="obj-dots">
                The trial evaluates recovery in moderate to severe disability
                following ischemic stroke
              </p>

              <p class="obj-dots">
                The study is designed to allow participants to receive the BQ
                therapy in multiple setups (including home)
              </p>
              <p class="obj-dots">
                BrainQ technologies, the sponsor, maintains this website to help
                patients receive basic information about the study
              </p>
            </div> -->
          <div style="margin: -20px 0 30px">
            <button
              class="btn small mobile"
              v-if="!readmoreStudy"
              @click="readmoreStudy = true"
            >
              Read More
            </button>
            <button
              class="btn small mobile"
              v-if="readmoreStudy"
              @click="readmoreStudy = false"
            >
              Read Less
            </button>
          </div>
        </div>
      </div>
      <div class="living-with-the-therapy-sec" id="living-with-the-therapy">
        <div class="gray-box therapy">
          <h3 class="txt55 lefta">Living with the Therapy</h3>
          <p>
            The BQ device has been designed to blend into a patient's daily
            life, with minimal interference to their daily schedule (one 1-hour
            session per day).
          </p>
          <p>
            The BQ device is lightweight, and designed for maximum comfort and
            ease of use for both patient and caregiver. The device comes with a
            trolley which is portable, easy to store, and holds all the
            equipment necessary to perform the BQ therapy session successfully
            (such as mobile device and battery).
          </p>
        </div>
        <div class="therapy-img-wrap">
          <img src="@/assets/VSBrainQ-411.jpg" />
        </div>
      </div>
      <div class="science-behind-sec" id="science-behind">
        <h3 class="txt55">The Science Behind</h3>
        <div class="behind-wrap">
          <div class="video-wrap side">
            <div style="padding: 56.25% 0 0 0; position: relative">
              <iframe
                title="vimeo-player"
                src="https://player.vimeo.com/video/732077860?h=5a3772a0a5"
                style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background: #e8e8e8;
                "
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div class="behind-txt-wrap">
            <Behindbox v-for="hiw in science" :key="hiw._id" v-bind="hiw" />
          </div>
        </div>
      </div>
      <div class="locations-sec" id="locations">
        <div class="locations-bg">
          <div class="top-map">
            <h4 class="txt34 white location-text">Trial Locations</h4>
            <select
              name="select locations"
              id="select locations"
              class="select-locations"
              v-model="selectedLocation"
            >
              <option :value="{}" selected="" disabled="">
                Select Location for more info
              </option>
              <option
                v-for="location in locations"
                :key="location._id"
                :value="location"
              >
                {{ location.city }}
              </option>
            </select>
            <div v-if="selectedLocation.city" class="on-map-box">
              <div style="padding: 40px 40px 0 40px">
                <p class="city big">
                  {{ selectedLocation.city
                  }}<span class="state"> {{ selectedLocation.state }}</span>
                </p>
                <p>
                  {{ selectedLocation.about }}
                </p>
                <p class="map-info">{{ selectedLocation.address }}</p>
                <p class="map-info">{{ selectedLocation.phone }}<br /></p>
              </div>
              <iframe
                v-if="selectedLocation.address"
                class="small-map"
                :src="`https://www.google.com/maps/embed/v1/place?key=AIzaSyDWRR56SPrIOvZP8w5jzfES8l3hVfK7ZC8&q=${selectedLocation.address.replace(
                  /\s/,
                  '+'
                )}+${selectedLocation.city}`"
              ></iframe>
              <!-- <img src="@/assets/map.jpg" class="small-map" /> -->
            </div>
          </div>
        </div>
        <!-- <div class="map-wrap hide">
            <div class="map-side">
              <p class="city">New York City <span class="state">NY</span></p>
              <p class="city">New York City <span class="state">NY</span></p>
              <p class="city">New York City <span class="state">NY</span></p>
              <p class="city">New York City <span class="state">NY</span></p>
              <p class="city">New York City <span class="state">NY</span></p>
              <p class="city">New York City <span class="state">NY</span></p>
              <p class="city">New York City <span class="state">NY</span></p>
            </div>
            <div class="map">
              <img src="@/assets/map.jpg" />
            </div>
          </div> -->
      </div>
      <div id="steering-committee">
        <h4 style="text-align: center" class="txt55">Steering Committee</h4>
        <div class="committee-sec">
          <CommitteeBox
            v-for="person in committee"
            :key="person._id"
            v-bind="person"
          />
        </div>
      </div>
      <div class="faq-sec tool-sec" id="faq">
        <h4 style="text-align: center" class="txt55 space-after">FAQ</h4>
        <FaqBox v-for="question in faq" :key="question._id" v-bind="question" />
      </div>
    </div>
    <!-- <Contact /> -->

    <div id="contact" class="contact">
      <div class="form-wrap cont">
        <h3 class="txt34 space-after">Contact us</h3>
        <form
          @submit.prevent="sendContact"
          v-if="contactStatus != 'success'"
          class="form"
        >
          <input
            id="email"
            v-model="contact.email"
            type="email"
            class="txt-field"
            placeholder="Email"
          />
          <input
            id="firstName"
            placeholder="First Name"
            v-model="contact.firstName"
            type="text"
            class="txt-field"
          />
          <input
            id="lastName"
            placeholder="Last Name"
            v-model="contact.lastName"
            type="text"
            class="txt-field"
          />

          <textarea
            id="message"
            placeholder="Message"
            v-model="contact.message"
            class="txt-field big"
          ></textarea>

          <div class="form-btn-wrap">
            <input class="btn" type="submit" value="Submit" />
          </div>
        </form>
        <div class="tnx" v-if="contactStatus == 'success'">
          <p class="">
            Your feedback was received and will be addressed shortly. Thank you.
          </p>
        </div>
        <div class="error" v-if="contactStatus == 'fail'">
          <p>An error occurred while submitting the forum</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommitteeBox from "@/components/Committeebox.vue";
import FaqBox from "@/components/Faqbox.vue";
import Behindbox from "@/components/Behindbox.vue";
import Sidemenu from "@/components/Sidemenu.vue";
// import Contact from "@/components/Contact.vue";

export default {
  components: {
    Sidemenu,
    Behindbox,
    FaqBox,
    // Contact,
    CommitteeBox,
  },
  data: () => ({
    applied: false,
    selectedLocation: {},
    readmore: false,
    readmoreAbout: false,
    readmoreStudy: false,
    popupOpen: 1,
    popupOpenUs: false,
    science: [],
    locations: [],
    faq: [],
    committee: [],
    contactStatus: "",
    contact: { email: "", firstName: "", lastName: "", message: "" },
    form: { zip: "", name: "", phone: "", email: "", eligible: false },
    eligibility: {
      pregnant: "",
      willing: "",
      able: "",
      age: "",
      diagnosed: "",
      times: "",
      caregiver: "",
    },
  }),
  computed: {
    popupOpenUsWasShown() {
      return localStorage.getItem("popupOpenUsWasShown");
    },
  },
  mounted() {
    if (!this.popupOpenUsWasShown) {
      this.popupOpenUs = true;
    }
  },
  methods: {
    gaEventTrigger(eventName, eventAction, eventLabel) {
      window.dataLayer.push({
        'event': eventName,
        'eventAction': eventAction,
        'eventLabel': eventLabel
        });
    },
    handlePopupOpenUsDismiss() {
      this.popupOpenUs = false;
      localStorage.setItem("popupOpenUsWasShown", true);
    },
    async sendContact() {
      try {
        await this.$db.collection("contact").post(this.contact);
        this.contactStatus = "success";
      } catch (err) {
        console.log(err.response.data);
        this.contactStatus = "fail";
      }
    },
    async submitForm() {
      const { eligibility } = this;
      this.form.eligible =
        eligibility.willing &&
        eligibility.able &&
        eligibility.caregiver &&
        !["Under 18", "80+"].includes(eligibility.age) &&
        eligibility.diagnosed &&
        eligibility.times;
      await this.$db
        .collection("evaluation")
        .post({ ...this.form, ...this.eligibility });
      this.applied = true;
      this.popupOpen = false;
      const el = document.querySelector("#eligibility");
      if (el) el.scrollIntoView();
    },
    checkEligibility(step) {
      if (step === 2) this.gaEventTrigger('form_fill', 'step_2', 'eligibility');
      const el = document.querySelector("#eligibility");
      if (el) el.scrollIntoView();
      this.popupOpen = step;
      if (this.popupOpen <= 3) return;
      this.gaEventTrigger('form_fill', 'step_3', 'eligibility');
      const { eligibility } = this;
      if (
        eligibility.willing &&
        eligibility.able &&
        eligibility.caregiver &&
        !["Under 18", "80+"].includes(eligibility.age) &&
        eligibility.diagnosed &&
        eligibility.times
      )
        this.popupOpen = 3;
      else {
        this.popupOpen = 4;
        this.gaEventTrigger('conversion', 'step_4', 'eligibility');
      }
    },
  },
  async beforeMount() {
    let { data: locations } = await this.$db.collection("locations").get();
    this.locations = locations;
    let { data: science } = await this.$db
      .collection("scienceBehind")
      .sort("order", "asc")
      .get();
    this.science = science;
    let { data: faq } = await this.$db
      .collection("faq")
      .sort("order", "asc")
      .get();
    this.faq = faq;
    let { data: committee } = await this.$db
      .collection("steeringCommittee")
      .sort("order", "asc")
      .get();
    this.committee = committee;
  },
  name: "HomePage",
};
</script>
